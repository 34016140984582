<template lang="">
  <div class="content-box">
    <div class="title-area">
      <h2 class="title-b">설정 / 사용자 상세정보</h2>
    </div>

    <div class="form-box mt-sm">
      <div class="form-group-box">
        <div class="form-item row-type">
          <label class="form-title">아이디</label>
          <div class="form-element">
            <input type="text" placeholder="ID" class="element-input size-lg" v-model="userID" readonly="readonly">
          </div>
        </div>

        <div class="form-item row-type">
          <label class="form-title">&nbsp;</label>
          <div class="form-element">
            <button class="element-btn bg-blue size-md" @click="passwdChangeShow()">비밀번호 변경</button>
          </div>
        </div>
        
      </div>

      <div class="form-group-box">
        <div class="form-item row-type">
          <label class="form-title">이름</label>
          <div class="form-element">
            <input type="text" placeholder="이름" v-model="userName" class="element-input size-lg">
            <p class="mt-1 px-3 text-xxs text-red-600" v-if="userName==''">필수 입력 사항 입니다.</p>
          </div>
        </div>
        <div class="form-item row-type">
          <label class="form-title">휴대폰</label>
          <div class="form-element">
            <input type="text" placeholder="휴대폰" v-model="userPhone" @keyup="validatePhone()" class="element-input size-lg">
            <p class="mt-1 px-3 text-xxs text-red-600" v-if="userPhone==''">필수 입력 사항 입니다.</p>
          </div>
        </div>
        <div class="form-item row-type">
          <label class="form-title">이메일</label>
          <div class="form-element">
            <input type="text" placeholder="이메일" v-model="userEmail" @keyup="validateEmail()" class="element-input size-lg">
            <p class="mt-1 px-3 text-xxs text-red-600" v-if="userEmail==''">필수 입력 사항 입니다.</p>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="userEmail!='' && !isValidataEmail">이메일 형식을 확인해 주세요.</p>
          </div>
        </div>
      </div>

      <div class="form-group-box">
        <div class="form-item row-type">
          <label class="form-title">권한유형</label>
          <div class="form-element">
            <input type="text" class="element-input size-lg" v-model="managerRole[userRole]" readonly="readonly">
          </div>
        </div>
      </div>

      <div class="form-group-box">
          <div class="form-item row-type size-wide">
              <label class="form-title">관리자 메모</label>
              <div class="form-element size-b">
                  <textarea name="" class="element-textarea" v-model="userDescription" placeholder="메모"></textarea>
              </div>
          </div>
      </div>
    </div>

    
    <div v-if="userRole != 'master'">
      <div class="title-area mt-40">
        <p class="title-c">단말기 알림 SMS</p>
        <div class="element-switch">
          <input class="checkbox-input" type="checkbox" id="smaple51-1" v-model="isAlert" />
          <label class="checkbox-label" for="smaple51-1"><span class="for-a11y">Toggle Checkbox</span></label>
        </div>
      </div>

      <div class="choice-group mt-s">
        <div class="element-checkbox checkbox-text">
          <input class="radio-input" type="checkbox" id="checkbox1" v-model="alertDetect" />
          <label class="checkbox-label" for="checkbox1">신규 불법 카메라 탐지</label>
        </div>
        <div class="element-checkbox checkbox-text">
          <input class="radio-input" type="checkbox" id="checkbox2" v-model="alertOffline" />
          <label class="checkbox-label" for="checkbox2">단말기 오프라인</label>
        </div>
        <div class="element-checkbox checkbox-text">
          <input class="radio-input" type="checkbox" id="checkbox3" v-model="alertMemo" />
          <label class="checkbox-label" for="checkbox3">위치 정보에 단말메모 사용</label>
        </div>
      </div>

      <div class="note-group">
        <div class="note-item">
          <p class="note-title">불법 카메라 탐지 알림 문구</p>
          <div class="note-content">
            <span class="note-value">{관리구역명}</span>
            <span class="note-value">{상세위치}</span>
            <span class="note-value" v-if="alertMemo">{단말메모}</span>
            <span class="note-value">{단말기명}</span>
            <input type="text" class="w-3/5 py-2 px-1 outline-none" v-model="textDetect" placeholder="에서 신규 불법 카메라가 탐지되었습니다. 확인 바랍니다." />
          </div>
        </div>
        <div class="note-item">
          <p class="note-title">단말기 오프라인 알림 문구</p>
          <div class="note-content">
            <span class="note-value">{관리구역명}</span>
            <span class="note-value">{상세위치}</span>
            <span class="note-value" v-if="alertMemo">{단말메모}</span>
            <span class="note-value">{단말기명}</span>
            <input type="text" class="w-3/5 py-2 px-1 outline-none" v-model="textOffline" placeholder="가 오프라인 상태입니다. 확인 바랍니다." />
          </div>
        </div>
      </div>
    </div>

    <div class="page-btn-area">
      <!-- <button type="button" class="element-btn bg-blue size-lg" @click="userUpdate()" :disabled="checkUpdateDisable()">저장</button> -->
      <button type="button" class="element-btn bg-blue size-lg" @click="userUpdate()">저장</button>
    </div>


    <!-- 비밀번호 변경 다이얼로그 START -->
    <div class="module-dimmed" v-if="showPasswdDialog">
      <div class="module-dialog">
        <div class="dialog-body">
          <div class="dialog-content">
            <p class="text-lg font-bold text-red-700 text-center mb-10">비밀번호 변경</p>

            <div class="form-group">
              <div class="form-item">
                <span class="form-label">현재 비밀번호<em class="essential">*</em></span>
                <div class="form-inp">
                  <input type="password" class="element-input size-lg" v-model="userOldPasswd" placeholder="현재 비밀번호">
                  <p class="mt-1 px-3 text-xxs text-red-600" v-if="userOldPasswd==''">필수 입력 사항 입니다.</p>
                </div>
              </div>

              <div class="form-item">
                <span class="form-label">새 비밀번호<em class="essential">*</em></span>
                <div class="form-inp">
                  <input type="password" class="element-input size-lg" v-model="userNewPasswd" placeholder="새 비밀번호">
                  <p class="mt-1 px-3 text-xxs text-red-600" v-if="userNewPasswd==''">필수 입력 사항 입니다.</p>
                  <p class="mt-1 px-3 text-xxs text-red-600" v-if="!validatePasswd(userNewPasswd)">비밀번호를 확인해 주세요.(하나 이상의 문자,숫자,특수문자 8자리 이상)</p>
                </div>
              </div>

              <div class="form-item">
                <span class="form-label">새 비밀번호 확인<em class="essential">*</em></span>
                <div class="form-inp">
                  <input type="password" class="element-input size-lg" v-model="userNewPasswdCheck" placeholder="새 비밀번호 확인">
                  <p class="mt-1 px-3 text-xxs text-red-600" v-if="userNewPasswdCheck==''">필수 입력 사항 입니다.</p>
                  <p class="mt-1 px-3 text-xxs text-red-600" v-if="userNewPasswd!=userNewPasswdCheck">비밀번호 확인이 다릅니다.</p>
                </div>
              </div>
            </div>

          </div>

          
          
        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-red size-lg" @click="passwdChangeCancel()">취소</button>
          <button type="button" class="element-btn bg-blue size-lg" @click="passwdChangeAction()">완료</button>
        </div>
        <button type="button" class="btn-close" @click="passwdChangeCancel()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div>
    <!-- 비밀번호 변경 다이얼로그 END -->


  </div>
</template>

<script>
import { requestApi } from "@/utils/api"
import { mapGetters, mapActions } from "vuex"
import { richSelectXL } from "@/references/fixedClass"

import { managerRole } from "@/references/config"
import { encryptString, decryptString } from '@/utils/config'

export default {
  created () {
    this.companyGuid = this.getCompanyGuid
    this.getManagerInfo();
  },
  computed: {
    ...mapGetters(["getIsOverlay","getCurrentUser","getCompanyGuid"]),
  },
  data () {
    return {
      companyGuid: '',   //테스트로 일단 하드코딩 합니다.
      managerRole,
      richSelectXL,
      roleList: [
        {id:'member',value:'운영자'},
        {id:'agent',value:'현장관리자'},
      ],
      userID: '',
      userName: '',
      userPhone: '',
      userRole: '',
      userEmail: '',
      userDescription: '',
      userGuid: '',
      
      isAlert: true,
      alertDetect: false,
      alertOffline: false,
      alertMemo: false,
      textDetect: '',
      textOffline: '',
      isValidataEmail: false,

      isCheckManagerID: false,

      showPasswdDialog: false,
      userOldPasswd: '',
      userNewPasswd: '',
      userNewPasswdCheck: '',
    }
  },
  methods: {
    ...mapActions(["chgIsOverlay","chgIsAlert"]),
    async getManagerInfo () {
      let reqObj = {}
      reqObj.target = `/manager/info/${this.getCurrentUser.ur.userGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        if(result.msg.retData == null) {
          this.chgIsAlert({status:true,string:'잘못된 접근 입니다.'})

          this.$router.push({name:'IndexPage'})
        }
        else {
          this.userID = decryptString(result.msg.retData.managerID)
          this.userName = decryptString(result.msg.retData.managerName)
          this.userPhone = decryptString(result.msg.retData.managerPhone)
          this.userEmail = decryptString(result.msg.retData.managerEmail)
          this.userRole = result.msg.retData.managerRole
          this.userDescription = result.msg.retData.managerDescription

          this.isAlert = result.msg.retData.isUseSMS === 'yes' ? true : false
          this.alertDetect = result.msg.retData.isDetectAlarm === 'yes' ? true : false
          this.alertOffline = result.msg.retData.isOffLineAlarm === 'yes' ? true : false
          this.alertMemo = result.msg.retData.isMemoAlarm === 'yes' ? true : false

          this.textDetect = result.msg.retData.detectWords
          this.textOffline = result.msg.retData.offLineWords

          this.userGuid = result.msg.retData.managerGuid
          this.validateEmail()
        }
      }
      else {
        this.chgIsAlert({status:true,string:'잘못된 접근 입니다.'})

        this.$router.push({name:'IndexPage'})
      }
    },
    
    validateEmail () {
      if (/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(this.userEmail)) this.isValidataEmail = true
      else this.isValidataEmail = false
    },
    validatePhone () {
      this.userPhone = this.userPhone.replace(/[^0-9]/g, '');
    },
    validatePasswd (str) {
      // let passwdReg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      let passwdReg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[~!@#$%^&*()_+<>?])[A-Za-z\d~!@#$%^&*()_+<>?]{8,}$/;
      if(str == "" || !passwdReg.test(str)) return false
      else return true
    },

    checkUpdateDisable () {
      let retVal = true;
      if(this.userID !== "" 
      && this.userName !== ""
      && this.userPhone !== ""
      && this.userEmail !== ""
      && this.isValidataEmail
      ) retVal = false;
      return retVal;
    },

    userUpdate () {
      if(this.userName === '') {
        this.chgIsAlert({status:true,string:'이름을 입력해 주세요.'})
        return;
      }

      let phoneReg = /^01([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$/
      if(this.userPhone === '' || !phoneReg.test(this.userPhone)) {
        this.chgIsAlert({status:true,string:'휴대폰번호를 확인해 주세요.'})
        return;
      }

      if(this.userEmail === '') {
        this.chgIsAlert({status:true,string:'이메일을 입력해 주세요.'})
        return;
      }

      if(!this.isValidataEmail) {
        this.chgIsAlert({status:true,string:'이메일 형식을 확인해 주세요.'})
        return;
      }

      this.userUpdateAction();
    },
    async userUpdateAction() {
      let reqObj = {}
      reqObj.target = "/user/update";
      reqObj.method = "put";
      reqObj.params = {};
      reqObj.data = {
        "alertDetect": this.alertDetect ? 'yes' : 'no',
        "alertOffline": this.alertOffline ? 'yes' : 'no',
        "alertMemo": this.alertMemo ? 'yes' : 'no',
        "isAlert": this.isAlert ? 'yes' : 'no',
        "userDescription": this.userDescription,
        "userEmail": encryptString(this.userEmail),
        "userName": encryptString(this.userName),
        "userPhone": encryptString(this.userPhone),
        "textDetect": this.textDetect,
        "textOffline": this.textOffline,
        "userGuid": this.userGuid
      };
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.chgIsAlert({status:true,string:'수정 했습니다.'})
      }
    },

    passwdChangeShow () {
      this.showPasswdDialog = true
    },

    passwdChangeAction () {
      if(this.userOldPasswd == '') {
        this.chgIsAlert({status:true,string:'현재 비밀번호를 입력해 주세요.'})
        return
      }

      if(this.userNewPasswd != this.userNewPasswdCheck) {
        this.chgIsAlert({status:true,string:'새 비밀번호를 확인해 주세요.'})
        return
      }

      if(!this.validatePasswd(this.userNewPasswd)) {
        this.chgIsAlert({status:true,string:'비밀번호를 확인해 주세요.(하나 이상의 문자,숫자,특수문자 8자리 이상)'})
        return
      }
      this.passwdChangeActionSubmit()
    },

    async passwdChangeActionSubmit () {
      let reqObj = {}
      reqObj.target = "/user/updatePasswd";
      reqObj.method = "put";
      reqObj.params = {};
      reqObj.data = {
        "userID": encryptString(this.userID),
        "userGuid": this.userGuid,
        "userOldPassword": encryptString(this.userOldPasswd),
        "userNewPassword": encryptString(this.userNewPasswd)
      };
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.chgIsAlert({status:true,string:'수정 했습니다.'})
        this.showPasswdDialog = false
      }
      else {
        let errorMsg = "사용자 확인에 실패했습니다."
        console.log(result)
        switch(result.msg) {
          case 'wrongPassword':
            errorMsg = "현재 비밀번호가 다릅니다."
          break;
          case 'notMatchID':
            errorMsg = "사용자 에러 입니다."
          break;
          default:
            errorMsg = "사용자 확인에 실패했습니다."
          break;
        }

        this.chgIsAlert({status:true,string:errorMsg})
      }
    },

    passwdChangeCancel () {
      this.showPasswdDialog = false
    }
    
  }
}
</script>
<style lang="">
  
</style>